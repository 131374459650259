import React from 'react';

import Header from './Header';
import Footer from './Footer';

import '../../node_modules/@fortawesome/fontawesome-free/css/all.css';
import '../stylesheets/index.scss';

const Layout = ({ location, title, children }) => {
  return (
    <>
      <Header location={location} />
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
