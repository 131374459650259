import React from 'react';
// import { Link } from 'gatsby';

const Header = ({ location }) => {
  // const rootPath = `${__PATH_PREFIX__}/`;

  // const isHomePage = location.pathname === rootPath;

  return <></>;
  // return (
  //   <header
  //     className={`header container-reading ${isHomePage ? 'header-home' : ''}`}>
  //     <nav className="header-link-container">
  //       <Link
  //         className="header-link"
  //         activeClassName="header-link-active"
  //         to={`/`}>
  //         Home
  //       </Link>

  //       <Link
  //         className="header-link"
  //         activeClassName="header-link-active"
  //         to={`/about`}>
  //         About
  //       </Link>
  //     </nav>
  //   </header>
  // );
};

export default Header;
